@import '@/styles/mixins.scss';

.mobile-pricing {
    @include flex-column;
    gap: 72px;

    margin-top: 40px;
}

.pricing-wrap {
    @include flex-column;
    gap: 24px;
    overflow: hidden;
}

.padding-wrap {
    padding: 0 24px;
}

.title {
    @include hero-headline;
    color: var(--colors-primary-dark-70);
    margin: 0;
}

.per-month {
    @include headline-2;
    color: var(--colors-gray);
}

.subtitle {
    @include headline-6;
    color: var(--colors-primary);
}

.pricing-title-wrap {
    @include flex-column;
    gap: 24px;

    padding: 0 40px;
}

.hr {
    height: 1px;
    background: var(--colors-mono-black-opacity-5);
    width: 85vw;
    margin: 0 auto;
}

.features {
    @include flex-column;
    gap: 16px;

    padding: 0 40px;
}

.feature-title {
    @include caption-L;
    color: var(--colors-gray-dark-20, #7a828f);
}

.feature {
    @include flex-row;
    gap: 12px;
    align-items: center;

    .label {
        @include text-M;
        color: var(--colors-gray-dark-20);
    }
}

.button {
    margin: 0 40px;
}

.cards-wrap {
    @include flex-row;

    width: 100%;

    gap: 12px;
    overflow-x: auto;
    padding: 0 40px;

    overflow: auto;
    scroll-snap-type: x mandatory;

    transition: 100ms ease-out;
}

.card {
    @include flex-column;
    padding: 32px 24px 24px 24px;
    border-radius: 16px;
    background: linear-gradient(124deg, #0d4491 0%, #1570ef 100.05%);
    width: fit-content;
    justify-content: space-between;

    scroll-snap-align: center;
    scroll-snap-stop: always;

    cursor: pointer;
}

.card-top-wrap {
    @include flex-column;
    gap: 32px;
}

.card-title-wrap {
    @include flex-column;
    gap: 10px;
}

.card-title {
    @include headline-6;
    color: var(--colors-primary-light-60);
}

.card-price-wrap {
    @include flex-row;
    align-items: center;
    gap: 4px;
}

.card-price {
    @include hero-headline;
    color: var(--white);
    margin: 0px;
    padding-top: 4px;
}

.card-secondary-wrap {
    @include flex-column;
    @include headline-4;
    color: var(--colors-primary-light-60);
}

.card-price-vat {
    @include subtitle-XS;
}

.card-feature-wrap {
    @include flex-column;
    gap: 12px;
}

.card-feature {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px dashed var(--colors-mono-white-opacity-20);
}

.card-label {
    @include subtitle-L;
    color: var(--white);
}

.card-info {
    @include subtitle-S;
    color: var(--white);

    ul {
        margin: 0;
        padding-left: 24px;
    }
}

.bolt-wrap {
    @include flex-column;
    gap: 16px;

    padding: 0 40px;
}

.bolt-title-wrap {
    @include flex-row;
    align-items: center;
    justify-content: space-between;

    svg {
        width: 20px;
        height: 20px;
        color: var(--colors-gray-light-50);
    }
}

.bolt-title {
    @include caption-L;
    color: var(--colors-gray-dark-20);
}

.bolt-services {
    @include flex-column;
    gap: 12px;
}

.bolt-service {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px dashed var(--colors-mono-black-opacity-5);

    .label {
        @include text-M;
        color: var(--colors-gray-dark-20);
    }

    .price-row {
        @include flex-row;
        align-items: flex-end;
        gap: 8px;
    }

    .price-wrap {
        @include flex-center;
        padding: 4px 8px;
        border-radius: 4px;
        background: var(--colors-primary-light-90, #e8f1fd);

        &.green {
            background: var(--colors-success-light-90);
            span {
                color: var(--colors-success-dark-20);
            }
        }

        &.discounted {
            @include caption-M;
            background: var(--colors-warning-light-90, #fef4e6);
            padding: 2px 6px;
            color: var(--colors-warning-dark-20, #c67307);

            span {
                text-decoration: line-through;
            }
        }
    }

    .price {
        @include caption-L;
        color: var(--colors-primary-dark-20);
    }
}

.seperator {
    height: 1px;
    background: var(--colors-mono-black-opacity-5);
    width: 100%;
    margin: 24px 0;
}

.modal {
    padding: 40px;

    .modal-title-wrap {
        margin-bottom: 20px;

        .modal-title {
            @include headline-3;
            color: var(--colors-gray-dark-80);

            &.small {
                @include headline-5;
                color: var(--colors-gray-dark-50);
            }
        }
    }

    .modal-content {
        @include text-M;
        margin: 0;
        color: var(--colors-gray-dark-20);
    }

    ul {
        @include flex-column;
        gap: 16px;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        @include flex-row;
        @include text-M;
        color: var(--colors-gray-dark-20);
        gap: 12px;
        align-items: center;
    }
}

.list-icon {
    width: 24px;
    height: 24px;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg {
        width: 24px;
        height: 24px;
        stroke: var(--colors-gray-light-50);
    }
}

@media screen and (max-width: 768px) {
    .card {
        min-width: 300px;
    }
}

@media screen and (max-width: 524px) {
    .bolt-wrap,
    .features,
    .pricing-title-wrap {
        padding: 0 24px;
    }

    .button {
        margin: 0 24px;
    }

    .cards-wrap {
        padding: 0 24px;
    }
}

.bulk-message {
    @include text-M;
    margin-top: 20px;
    padding: 12px;
    text-align: center;

    color: var(--colors-primary);
    cursor: pointer;
}
