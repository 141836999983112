@import '@/styles/mixins.scss';

.wrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 15px));
    gap: 30px;

    align-items: center;

    padding: 80px 0 80px;

    &.hero {
        padding: 40px 0 60px;
    }
}

.content-arrows-wrapper {
    @include flex-column;
    align-items: flex-end;
    padding: 52px 0;
    margin-right: 50px;

    position: relative;
}

.content-wrapper {
    @include flex-column;

    gap: 16px;
}

.title {
    @include hero-headline;
    color: var(--colors-primary);

    margin: 0;
}

.description {
    @include text-L;
    color: var(--colors-gray-dark-50);

    *:first-child {
        margin-top: 0;
    }

    *:last-child {
        margin-bottom: 0;
    }
}

.icon-text-box-wrapper {
    @include flex-column;
    gap: 8px;
    margin-top: 24px;
}

.icon-text-box {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 8px;

    height: 34px;

    align-items: center;

    span {
        @include text-S;
        color: var(--colors-gray-dark-50);
        margin: 0;
    }
}

.form-wrapper {
    @include flex-center;
    position: relative;
    max-width: 630px;
    flex-shrink: 0;
    margin-left: auto;

    width: 100%;

    &.mobile {
        display: none;
    }
}

.button {
    width: fit-content;

    &:hover {
        box-shadow: none !important;
    }
}

.arrow-2 {
    width: 222px;
}

.arrow-3 {
    display: none;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        grid-template-columns: calc(55% - 20px) calc(45% - 20px);
        gap: 40px;
    }

    .content-arrows-wrapper {
        margin: 0;
        padding: 0;
    }

    .form-wrapper {
        width: stretch;
    }
}

@media screen and (max-width: 960px) {
    .section-wrap {
        padding: 0 40px;
    }

    .wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);

        gap: 48px;
    }

    .arrow-1 {
        display: none;
    }

    .arrow-2 {
        display: none;
    }

    .arrow-3 {
        display: block;
        position: absolute;
        right: 60px;
        bottom: -50px;
    }

    .content-arrows-wrapper.enabled {
        padding-bottom: 100px;
    }

    .form-wrapper {
        margin: auto;
        max-width: unset;
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        padding: 80px 0 60px;
    }

    .content-arrows-wrapper.enabled {
        padding-bottom: 50px;
    }

    .arrow-3 {
        display: block;
        position: absolute;
        right: 72px;
        bottom: -60px;

        height: 140px;
    }
}

@media screen and (max-width: 524px) {
    .section-wrap {
        padding: 0 24px;
    }

    .content-arrows-wrapper.enabled {
        padding-bottom: 20px;
    }

    .arrow-3 {
        display: block;
        position: absolute;
        right: 0;
        bottom: -80px;

        height: 140px;
        z-index: 20000;
    }
}
