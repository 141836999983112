@import '@/styles/mixins';

.default {
    @include text-M;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background-color: var(--white);
    border: 1px solid var(--colors-gray-light-50);
    width: 100%;
    line-height: 44px;
    padding: 0 14px;
    color: var(--colors-gray-dark-90);
    border-radius: 8px;
    margin-bottom: 0;
    outline: none;
    transition: 150ms ease-out box-shadow;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &.placeholder-hide {
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &::-ms-placeholder,
        &::placeholder {
            opacity: 0;
        }
    }

    &:hover {
        border: 1px solid var(--colors-primary-light-70);
        -webkit-box-shadow: 0px 0px 0px 4px var(--colors-primary-light-90);
        -moz-box-shadow: 0px 0px 0px 4px var(--colors-primary-light-90);
        box-shadow: 0px 0px 0px 4px var(--colors-primary-light-90);
    }

    &:focus {
        border: 1px solid var(--colors-primary-light-60);
        -webkit-box-shadow: 0px 0px 0px 4px var(--colors-primary-light-80);
        -moz-box-shadow: 0px 0px 0px 4px var(--colors-primary-light-80);
        box-shadow: 0px 0px 0px 4px var(--colors-primary-light-80);
    }

    &:invalid:not(:placeholder-shown) {
        border: 1px solid var(--colors-error-light-50);
    }

    &:invalid:not(:placeholder-shown):focus {
        -webkit-box-shadow: 0px 0px 0px 4px var(--colors-error-light-90);
        -moz-box-shadow: 0px 0px 0px 4px var(--colors-error-light-90);
        box-shadow: 0px 0px 0px 4px var(--colors-error-light-90);
    }
}
