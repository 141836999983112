@import '@/styles/mixins.scss';

.wrapper {
    @include flex-column;
    gap: 52px;

    &.no-padding {
        padding: 0;
    }

    &.no-top-padding {
        padding-top: 0;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(215px, 1fr);
    grid-gap: 24px;
    &.shadow-padding {
        padding: 0 60px 40px;
    }
}

.title-wrap {
    @include flex-row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
}

.small-button {
    margin: 0 auto;
}

.title-wrap {
    @include flex-row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
}

.option {
    @include flex-column;
    justify-content: flex-end;
    padding: 32px;
    gap: 12px;

    border-radius: 16px;
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);

    transition: 150ms ease-out;

    position: relative;
    overflow: hidden;

    text-decoration: none;

    &:hover {
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
    }

    transform: translateY(50px);
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transform: translateY(0);
        transition: 150ms ease-out;
    }
}

.option_1 {
    max-height: 215px;
}

.option_2 {
    grid-column: span 2;
}

.option_4 {
    grid-column: span 2;
    grid-row: span 2;
    padding: 40px;
    min-height: 400px;

    .title {
        @include headline-2;
    }
}

.tag {
    @include flex-row;
    @include caption-L;
    align-items: center;
    color: var(--colors-primary);
    gap: 8px;
    top: 40px;
    left: 40px;

    &.bottom {
        margin-top: 20px;
    }

    &.top {
        margin-bottom: auto;
    }

    svg {
        width: 16px;
        height: 16px;
    }
}

.title {
    @include headline-4;
    color: var(--colors-primary-dark-90, #020b18);
    display: -webkit-box;

    overflow: hidden;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.description {
    @include text-S;
    display: -webkit-box;
    color: var(--colors-gray-dark-40, #5b616b);

    overflow: hidden;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.image {
    position: absolute;
    top: 0;
    right: 0;
    width: 268px;
    height: 232px;
    z-index: -1;
}

.author {
    @include flex-center;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px 0;
}

.avatar {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    overflow: hidden;
}

.author-details {
    @include flex-column;
    gap: 4px;
}

.name {
    @include subtitle-S;
    color: var(--colors-primary-dark-90);
}

.publish-date {
    @include text-S;
    color: var(--colors-gray-light-30);
}

.pagination {
    @include flex-row;
    gap: 10px;
    align-items: center;
    margin: auto;
}

.svg-wrapper {
    svg {
        width: 24px;
        height: 24px;
        stroke: var(--colors-gray-light-50);

        &:hover {
            cursor: pointer;
        }
    }
}

.page {
    @include subtitle-XS;
    color: var(--colors-gray);
    padding: 6px 12px;

    &.active {
        border-radius: 5px;
        background: var(--colors-gray-light-90, #f5f6f7);
    }

    &:hover {
        cursor: pointer;
    }
}
@media screen and (max-width: 1280px) {
    .grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .option_2 {
        grid-column: span 1;
    }

    .option_4 {
        grid-column: span 1;
    }
}

@media screen and (max-width: 960px) {
    .tag.top {
        display: none;
    }

    .option {
        padding: 32px;
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        &.no-top-padding {
            padding-top: 40px;
        }
    }

    .option {
        grid-column: span 1;
        padding: 24px;
    }

    .option_4 {
        grid-column: span 2;
        padding: 32px;
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        &.no-top-padding {
            padding-top: 24px;
        }
    }

    .pagination {
        justify-content: center;
    }

    .grid {
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(215px, 1fr);

        &.shadow-padding {
            padding: 0 24px 24px;
        }
    }

    .option {
        padding: 24px;
    }

    .option_4 {
        grid-column: 1;

        .title {
            @include headline-4;
        }
    }

    .option_4 {
        .tag {
            display: none;
        }
    }
}
