@import '@/styles/mixins.scss';

.section-title {
    // margin: 0 auto 60px auto !important;
}

.gradient-end {
    position: absolute;
    bottom: -240px;
}
.container {
    @include flex-column;
    gap: 40px;
    margin: auto;
    padding: 80px;
    border-radius: 16px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--white, #fff);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
}

.header-wrap {
    @include flex-column;
    gap: 24px;
}

.header-wrap {
    @include flex-column;
    gap: 24px;
}

.title {
    @include headline-6;
    color: var(--colors-primary, #1570ef);
    margin: 0;
}

.subtitle {
    @include headline-2;
    color: var(--colors-primary-dark-90, #020b18);
    margin: 0;
}

.calculator-wrap {
    @include flex-row;
    gap: 24px;
    justify-content: space-between;
}

.calculator-inputs {
    @include flex-column;
    gap: 32px;
    width: 620px;
}

.dropdown-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    width: 100%;
    gap: 16px;
    align-items: center;
}

.input-section {
    @include flex-column;
    gap: 20px;
}

.section-splitter {
    @include flex-row;
    gap: 20px;
    align-items: center;
}

.input-title {
    @include caption-M-upper;
    color: var(--colors-gray-dark-0, #98a2b3);
}
.hr {
    height: var(--counter, 1px);
    border-radius: var(--counter, 0px);
    background: var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    flex-grow: 1;
}

.inputs-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px 16px;
    align-items: flex-end;
}

.input-container {
    @include flex-column;
    gap: 4px;
}

.input-wrapper {
    position: relative;

    input {
        padding-right: 28px;
    }

    span {
        color: var(--colors-gray-dark-50);
        position: absolute;
        right: 12px;
        top: 10px;
        user-select: none;
        font-weight: 700;
        pointer-events: none;
        width: fit-content;
    }

    &.disabled span {
        color: var(--colors-gray-light-90);
    }
}

.currency-input-container {
    @include subtitle-M;
    position: relative;
    height: 100%;
    display: block;

    input {
        padding-left: 24px !important;
    }

    span {
        color: var(--colors-gray-dark-30);
        font-weight: 400;
        position: absolute;
        left: 12px;
        top: 0 !important;
        padding-bottom: 20px;
        line-height: 46px;
    }
}

.svg-container {
    @include flex-center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--colors-gray-light-70, #e0e3e8);
    background: var(--white, #fff);
}

.radio-group {
    @include flex-row;
    gap: 12px;
}

.svg-container {
    @include flex-center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-50, #cbd1d9);
    background: var(--white, #fff);
}

/* Hide the browser's default checkbox */
.radio {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;

    &:checked + .radio-container .svg-container {
        border: 1px solid var(--colors-primary, #1570ef);
        background: var(--colors-primary, #1570ef);
    }
}

.help-wrap {
    position: relative;
    align-items: center;
    cursor: pointer;

    svg {
        width: 14px;
        height: 14px;
    }
}

.text-wrap {
    @include flex-column;
    position: absolute;
    left: 18px;
    top: -48px;
    width: 344px;
    padding: 24px;
    gap: 8px;
    z-index: 3;
    border-radius: 8px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 20px 60px 0px rgba(74, 84, 101, 0.15);

    .title {
        @include headline-6;
        color: var(--colors-gray-dark-60);
    }

    .text {
        @include text-S;
        color: var(--colors-gray-dark-20);
    }
}

.enter {
    opacity: 0;
}

.enter-active {
    opacity: 1;
    transition: 200ms ease-out;
}

.exit {
    opacity: 1;
}

.exit-active {
    opacity: 0;
    transition: 200ms ease-out;
}

.radio-container {
    @include text-M;
    width: 136px;
    display: inline-flex;

    gap: 8px;
    color: var(--colors-gray-dark-30, #6a717d);
    align-items: center;
    position: relative;
    cursor: pointer;

    svg {
        fill: var(--white);
    }
}

.label-row {
    @include flex-row;
    gap: 4px;
}

.label {
    @include subtitle-S;
    color: var(--colors-gray-dark-40, #5b616b);
}

.button-container {
    @include flex-row;
    gap: 16px;
    width: 100%;
}

.button {
    @include flex-center;
    @include cta-primary;
    width: 100%;
    padding: 12px 24px;
    gap: 8px;
    border-radius: 8px;

    &.primary {
        height: 45px;
        border-radius: 8px;
        background: var(--colors-primary, #1570ef);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
        color: var(--white);
    }

    &.reset {
        border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.1));
        background: var(--white, #fff);
        color: var(--colors-gray-dark-10);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
    }

    svg {
        stroke: var(--colors-gray-light-10, #a2abbb);
    }
}

.alert-box {
    @include flex-row;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--colors-success-light-90, #e7f8f0);
    background: var(--colors-gray-light-95, #fafafb);
    margin-top: 8px;

    :nth-child(3) {
        &:hover {
            cursor: pointer;
        }
    }
}

.svg-wrap {
    height: 20px;
    svg {
        stroke: var(--colors-gray, #98a2b3);
    }
}

.alert-text {
    @include subtitle-S;
    color: var(--colors-gray-dark-30, #6a717d);
    margin: 0;
    width: 90%;
}

.summary-wrap {
    @include flex-column;
    gap: 32px;
    width: 440px;
    height: fit-content;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid var(--colors-gray-light-80, #eaecf0);
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
}

.header-bubble {
    @include flex-center;
    width: min-content;
    padding: 4px 12px;
    border-radius: 16px;
    background: var(--colors-gray-light-95, #fafafb);
}

.header {
    @include subtitle-S;
    color: var(--colors-gray-dark-50, #4c515a);
}

.breakdown-title {
    @include headline-4;
    color: var(--colors-primary-dark-90, #020b18);
}

.border-summary {
    @include flex-center;
    height: 176px;
    border-radius: 12px;
    border: 1px solid var(--colors-gray-light-80, #eaecf0);
    background: var(--colors-mono-white-opacity-100, #fff);
    padding: 16px;
}

.empty-summary {
    @include flex-column;
    gap: 8px;

    svg {
        margin: 0 auto;
        stroke: var(--colors-gray-light-50, #cbd1d9);
    }
}

.empty-text {
    @include text-M;
    color: var(--colors-gray-light-50, #cbd1d9);
    text-align: center;
}

.summary-container {
    @include flex-column;
    gap: 24px;
}

.separator {
    height: 1px;
    background: var(--colors-gray-light-70, #e0e3e8);
}

.button-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;

    gap: 16px;
    width: 100%;
    grid-column: 1 / 3;
}

@media screen and (max-width: 1280px) {
    .container {
        padding: 80px 40px;
    }

    .input-container {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .container,
    .calculator-wrap {
        flex-direction: column;
    }

    .calculator-wrap,
    .calculator-inputs,
    .summary-wrap,
    .summary-container {
        width: 100%;
    }
}
@media screen and (max-width: 764px) {
    .dropdown-section {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .container {
        box-shadow: none;
        border-radius: 0;
        border: 0;
        padding: 44px 0;
    }
}

@media screen and (max-width: 524px) {
    .inputs-wrap {
        @include flex-column;
        align-items: flex-start;
        gap: 20px;
    }

    .dropdown-section {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (max-width: 420px) {
    .button-wrap {
        grid-template-columns: 1fr;
    }
}
