@import '@/styles/mixins.scss';

.graphic-wrapper {
    @include flex-center;
    background: var(--colors-gray-light-95);

    padding: 40px 0 32px;
}

.graphic {
    display: block;
    margin: 0 auto;
}

.wrapper {
    @include flex-column;
    padding: 32px;

    gap: 24px;
}

.content-wrapper {
    @include flex-column;
    gap: 12px;
}

.title {
    @include headline-4;
    color: var(--colors-gray-dark-80);

    margin: 0;
}

.description {
    @include text-M;
    color: var(--colors-gray-dark-40);
}

.form-wrapper {
    @include flex-row;
    align-items: flex-end;
    gap: 16px;
}

.input-wrapper {
    @include flex-column;
    gap: 4px;
}

.input-label {
    @include subtitle-S;
    color: var(--colors-gray-dark-40);

    .asterisk {
        color: var(--colors-error);
    }
}

.input {
    @include text-M;

    padding: 0 13px;
    width: 256px;
    height: 42px;

    background: white;
    border: 1px solid var(--colors-gray-light-70);
    border-radius: 8px;

    color: var(--colors-gray-dark-40);

    outline: none;
}

.button {
    border-radius: 8px;
}
