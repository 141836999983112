@import '@/styles/mixins.scss';

.summary-section {
    @include flex-column;
    gap: 24px;
}

.h4 {
    @include headline-4;
    color: var(--colors-gray-dark-90, #1d2129);
    margin: 0;
}

.output-wrap {
    @include flex-row;
    justify-content: space-between;
}

.summary-label {
    @include subtitle-S;
    color: var(--colors-gray-dark-20, #7a828f);

    &.total-section {
        color: var(--colors-gray-dark-50);
    }
}

.output {
    @include subtitle-S;
    color: var(--colors-gray);

    &.zero {
        color: var(--colors-gray-light-50);
    }
}

.keys {
    @include flex-column;
    gap: 12px;
}

.dots-separator {
    flex-grow: 1;
    margin: 4px;
    border-bottom: 1px dashed var(--colors-mono-black-opacity-10);
}

@media screen and (max-width: 800px) {
    .summary-container {
        width: 100%;
    }
}
