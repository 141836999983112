@import '@/styles/mixins.scss';

.wrap {
    @include flex-column;
    max-width: 866px;
    gap: 16px;

    &.center {
        align-items: center !important;
        margin: auto;

        .description,
        .title,
        .subtitle {
            text-align: center;
        }
    }

    &.right {
        align-items: flex-end;
    }

    > * {
        max-width: 1000px;
    }

    &.first-element {
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;

        .title {
            @include hero-headline;
            text-align: center;
        }
    }

    &.white-text {
        .title,
        .subtitle,
        .description {
            color: var(--white);
        }
    }

    &.small {
        .subtitle {
            @include cta-primary;
        }

        .title {
            @include headline-2;
        }

        .description {
            @include text-M;
        }
    }

    &.standalone {
        padding-top: 100px;
    }
}

.subtitle {
    @include caption-M;
    color: #175cd3;
    margin: 0;
    opacity: 0.01;

    &.left {
        transform: translateX(-100px);

        &.enter {
            opacity: 1;
            transform: translateX(0);
            transition: 150ms ease-out;
        }
    }

    &.center {
        transform: translateY(30px);

        &.enter {
            opacity: 1;
            transform: translateY(0);
            transition: 150ms ease-out;
        }
    }

    &.right {
        transform: translateX(100px);

        &.enter {
            opacity: 1;
            transform: translateX(0);
            transition: 150ms ease-out;
        }
    }
}

.title {
    @include headline-1;
    color: var(--colors-gray-dark-90);
    margin: 0;
    opacity: 0.01;

    &.left {
        transform: translateX(-100px);

        &.enter {
            opacity: 1;
            transform: translateX(0);
            transition: 150ms ease-out;
            transition-delay: 200ms;
        }
    }

    &.center {
        transform: translateY(30px);

        &.enter {
            opacity: 1;
            transform: translateY(0);
            transition: 150ms ease-out;
            transition-delay: 200ms;
        }
    }

    &.right {
        transform: translateX(100px);

        &.enter {
            opacity: 1;
            transform: translateX(0);
            transition: 150ms ease-out;
            transition-delay: 200ms;
        }
    }
}

.description {
    @include subtitle-L;
    color: var(--colors-gray-dark-40);
    opacity: 0.01;

    p {
        margin: 0;
    }

    &.left {
        transform: translateX(-100px);

        &.enter {
            opacity: 0.8;
            transform: translateX(0);
            transition: 150ms ease-out;
            transition-delay: 350ms;
        }
    }

    &.center {
        transform: translateY(30px);

        &.enter {
            opacity: 0.8;
            transform: translateY(0);
            transition: 150ms ease-out;
            transition-delay: 350ms;
        }
    }

    &.right {
        transform: translateX(100px);

        &.enter {
            opacity: 0.8;
            transform: translateX(0);
            transition: 150ms ease-out;
            transition-delay: 350ms;
        }
    }
}

.gradient-end {
    position: absolute;
    bottom: -186px;

    &.small {
        bottom: 0px;
    }
}

@media screen and (max-width: 1280px) {
    .gradient-end {
        bottom: -140px;
    }

    .wrap {
        max-width: 720px;
    }
}

@media screen and (max-width: 960px) {
    .wrap {
        margin: auto;

        &.first-element {
            align-items: center;

            .title,
            .description,
            .subtitle {
                text-align: center;
            }
        }
    }
    .gradient-end {
        bottom: -178px;
    }

    .wrap {
        max-width: 604px;
    }
}

@media screen and (max-width: 764px) {
    .gradient-end {
        bottom: 0px;
    }

    .wrap {
        &.standalone {
            padding-top: 80px;
        }
    }
}

@media screen and (max-width: 524px) {
    .title {
        @include headline-2;
        text-align: center;
    }

    .description {
        @include text-M;
        text-align: center;
    }

    .subtitle {
        text-align: center;
    }
}
