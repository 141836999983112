@import '@/styles/mixins';

.wrapper {
    @include flex-column;
    width: 100%;
    // width: 273px;
    position: relative;
    overflow: visible;
    gap: 6px;
    border-radius: 8px;
}

.title {
    @include subtitle-S;
    color: var(--colors-gray-dark-40);
}

.selection-box {
    @include flex-row;
    gap: 10px;
    height: 41px;
    padding: 0px 13px;
    border-radius: 8px;
    border: 1px solid var(--colors-mono-black-opacity-20, rgba(0, 0, 0, 0.2));
    background: var(--white);
    align-items: center;
    user-select: none;

    user-select: none;
    cursor: pointer;

    &.active {
        border: 2px solid var(--colors-primary-light-60);
        background: var(--white);
    }

    svg {
        width: 18px;
        height: 18px;
        stroke: #adb5c2;
    }
}

.selected-list {
    @include text-M;
    flex-grow: 1;
    overflow: hidden;
    color: var(--colors-gray-dark-40);
    text-overflow: ellipsis;

    display: -webkit-box;
    height: 24px;
    margin: 0;

    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-break: anywhere;
}

.dropdown-wrapper {
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 74px;
    border-radius: 8px;
    background: var(--white);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);

    border: 1px solid transparent;

    &.active {
        border: 1px solid var(--colors-mono-black-opacity-10);
    }
}

.dropdown-wrapper,
.dropdown-content {
    transition: 150ms ease-in-out;
}

.dropdown-controls {
    @include flex-row;
    padding: 16px 16px 8px 16px;
    justify-content: space-between;
}

.control {
    @include caption-M-upper;
    color: var(--colors-primary);
    cursor: pointer;
}

.options {
    @include flex-column;
    padding: 0px 13px;
    gap: 4px;
    border-radius: 0px;
    max-height: 204px;
    overflow: auto;
}

.option {
    :hover {
        cursor: pointer;
    }
}

.label {
    @include flex-row;
    height: 41px;
    padding: 0px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 0px;
}

.checkbox {
    display: none;

    &:checked + .label {
        .option-title {
            color: var(--colors-primary);
        }

        .fake-checkbox {
            border: 1px solid var(--colors-primary);
            background: var(--colors-primary-light-90, #d0e2fc);

            svg {
                display: block;
                fill: var(--colors-primary);
            }
        }
    }
}

.fake-checkbox {
    @include flex-center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--colors-gray-light-70);
    background: var(--white);

    svg {
        display: none;
    }
}

.option-title {
    @include text-M;
    color: var(--colors-gray-dark-20);
    user-select: none;
}

.bottom-box {
    @include flex-center;
    height: 48px;
    padding: 0px 10px 0px 16px;
    justify-content: space-between;
    border-top: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
}

.count {
    @include text-XS;
    color: var(--colors-gray);
}

.button-wrap {
    @include flex-row;
    gap: 8px;
}

.button {
    @include flex-center;
    @include caption-M;
    padding: 6px 12px;
    border-radius: 6px;

    &:hover {
        cursor: pointer;
    }

    &.submit-button {
        color: var(--white);
        background: var(--colors-primary);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    }

    &.cancel-button {
        background: var(--white);
        color: var(--colors-primary);
    }
}
