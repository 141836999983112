@import '@/styles/mixins.scss';

.wrapper {
    padding-top: 0 !important;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(298px, 1fr);
    grid-gap: 24px;
}

.option {
    @include flex-column;
    justify-content: flex-end;
    min-height: 298px;
    padding: 32px 32px 28px 32px;
    gap: 12px;

    border-radius: 16px;
    background: var(--colors-mono-white-opacity-100, #fff);
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);

    transition: 150ms ease-out;

    position: relative;
    overflow: hidden;

    text-decoration: none;

    &:hover {
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
    }

    transform: translateY(50px);
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transform: translateY(0);
        transition: 150ms ease-out;
    }
}

.option_2 {
    grid-column: span 2;
}

.option_4 {
    grid-column: span 2;
    grid-row: span 2;

    .title {
        @include headline-2;
    }
}

.title {
    @include headline-4;
    color: var(--colors-primary-dark-90, #020b18);
}

.description {
    @include text-S;
    color: var(--colors-gray-dark-40, #5b616b);
}

.button {
    @include flex-center;
    @include cta-primary;

    justify-content: flex-start;
    gap: 8px;

    color: var(--colors-primary);

    margin-top: 8px;

    svg {
        width: 16px;
        height: 16px;
    }
}

.image {
    position: absolute;
    top: 0;
    right: 0;
    width: 268px;
    height: 232px;
}

@media screen and (max-width: 1280px) {
    .grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .option_2 {
        grid-column: span 1;
    }

    .option_4 {
        grid-column: span 1;
        grid-row: span 2;
    }

    #__take_home {
        grid-column: 2;
        grid-row: 1 / span 2;
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        padding-top: 40px !important;
    }

    .option_4 {
        grid-column: span 2;
    }

    #__take_home {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        padding-top: 24px !important;
    }

    .grid {
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(280px, 1fr);
    }

    .option_4 {
        grid-column: 1;
    }

    #__take_home {
        grid-column: 1;
        grid-row: 1 / span 2;
    }
}
