@import '@/styles/mixins.scss';

.wrapper {
    width: 100%;
    height: 572px;
    background-color: var(--white);
    border-radius: 16px;
    border: 1px solid var(--colors-gray-light-90);
    box-shadow: 0px 4px 40px 0px #0000000d;
    overflow: hidden;
    transition: 150ms ease-out;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        box-shadow: 0px 4px 50px 0px #1570ef33;
        transform: scale(1.01);
    }

    transform: translateY(50px);
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transform: translateY(0);
    }
}

.image {
    height: 304px;
    position: relative;

    img {
        object-fit: cover;
        object-position: center;
    }
}

.content {
    @include flex-column;
    gap: 8px;
    padding: 32px;
}

.tag {
    @include caption-L;
    color: var(--colors-primary);
    margin: 0;
}

.title {
    @include headline-4;
    display: -webkit-box;
    height: 54px;
    margin: 0;
    color: var(--colors-primary-dark-90);

    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.excerpt {
    @include text-S;
    display: -webkit-box;
    height: 44px;
    margin: 0;
    color: var(--colors-gray-dark-10);

    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.author {
    @include flex-center;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px 0;
}

.avatar {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    overflow: hidden;
}

.author-details {
    @include flex-column;
    gap: 4px;
}

.name {
    @include subtitle-S;
    color: var(--colors-primary-dark-90);
}

.publish-date {
    @include text-S;
    color: var(--colors-gray-light-30);
}

@media screen and (max-width: 1100px) {
    .third-item {
        grid-column: 1 / 3;
    }
}

@media screen and (max-width: 600px) {
    .third-item {
        grid-column: 1;
    }
}
