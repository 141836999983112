@import '@/styles/mixins.scss';

@mixin with-count($n) {
    &:has(th:nth-child(#{$n})) th:not(:first-child),
    &:has(td:nth-child(#{$n})) td:not(:first-child) {
        @content;
    }
}

.pricing-table-wrapper {
    position: relative;
    padding: 0 70px;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th {
        padding: var(--sp20) var(--sp20) var(--sp32);

        text-align: left;
        vertical-align: bottom;
    }

    thead tr,
    tbody tr {
        @include with-count(3) {
            width: 300px;
            max-width: 300px;
        }

        @include with-count(4) {
            width: 285px;
            max-width: 285px;
        }

        @include with-count(5) {
            width: 228px;
            max-width: 228px;
        }

        @include with-count(6) {
            width: 190px;
            max-width: 190px;
        }
    }

    td {
        padding: 24px 20px;
    }

    tbody {
        tr {
            background: white;

            overflow: hidden;

            &:last-child {
                td:first-child {
                    border-bottom-left-radius: 12px;
                }

                td:last-child {
                    border-bottom-right-radius: 12px;
                }
            }

            &:nth-child(even) {
                background: white;
            }

            &:nth-child(odd) {
                background: var(--colors-gray-light-95);
            }
        }

        &.sub-section-body {
            tr:nth-child(even) {
                background: var(--colors-gray-light-95);
            }

            tr:nth-child(odd) {
                background: white;
            }
        }
    }
}

.compare-text-cell {
    @include headline-6;
    color: var(--colors-primary-dark-70);
}

.package-header-content {
    @include flex-column;
    gap: 20px;
}

.package-subtitle {
    @include caption-L;
    color: var(--colors-primary);
    margin-bottom: 10px;
}

.small .package-subtitle {
    @include caption-M;
}

.package-text-wrapper {
    @include flex-row;
    align-items: center;
    gap: 4px;
}

.package-primary-text {
    @include headline-1;
    color: var(--colors-primary-dark-70);
    margin: 0;
    padding-top: 4px;
}

.package-secondary-text-wrapper {
    @include flex-column;
    color: var(--colors-gray-dark-20);
}

.package-secondary-text {
    @include subtitle-L;
}

.package-vat {
    @include subtitle-XS;
}

.button {
    @include caption-L;
    padding: 12px 20px !important;
}

.feature-title-cell {
    padding: 24px 20px;
}

.feature-title-wrapper {
    @include flex-column;
    gap: 12px;
}

.feature-title {
    @include headline-6;
    color: var(--colors-gray-dark-20);
}

.feature-subtext {
    @include text-S;
    color: var(--colors-gray);
}

.table-cell-wrapper {
    @include flex-center;

    svg {
        width: 24px;
    }
}

.subsection-row {
    background: white !important;
}

.subsection-wrap {
    margin-top: 32px;
}

.subsection-title-wrap {
    @include flex-row;
    gap: 12px;

    background: var(--colors-gray-light-95);

    border-radius: 12px;

    padding: 24px 20px;

    cursor: pointer;

    &.open {
        border-radius: 12px 12px 0 0;
    }
}

.subsection-title {
    @include headline-6;
    color: var(--colors-gray-dark-70);
}

.chevron {
    height: 24px;
    height: 24px;

    transform: rotate(0deg);

    stroke: var(--colors-primary);

    &.open {
        transform: rotate(180deg);
    }
}

.price-wrap {
    @include flex-center;
    gap: 8px;
}

.price {
    @include caption-L;
    border-radius: 4px;
    background: var(--colors-primary-light-90);

    padding: 4px 8px;

    color: var(--colors-primary-dark-20);

    &.discounted {
        background: var(--colors-success-light-90);
        color: var(--colors-success-dark-20);
    }

    &.previous {
        @include caption-M;
        text-decoration: line-through;
        background: var(--colors-warning-light-90);
        color: var(--colors-warning-dark-20);
    }
}

// Single package

.single-package-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 24px;
    justify-content: center;

    max-width: 1070px;
    width: 100%;
    padding: 0;
    margin: auto;

    svg {
        width: 24px;
    }
}

.package-header-content.single {
    width: 230px;

    padding: 20px 10px 32px;
}

.single-package-content {
    flex-grow: 1;
}

.graphic {
    background-image: url('/images/plan-benefits.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;

    max-width: 420px;
    width: 100%;
    margin-left: auto;
}

.list-wrapper {
    padding: 0 20px;
    overflow-y: auto;
}

.benefits-wrapper {
    @include flex-column;

    div:nth-child(1) {
        border-radius: 12px 12px 0 0;
    }

    div:last-child {
        border-radius: 0 0 12px 12px;
    }

    div:nth-child(even) {
        background: transparent;
    }

    div:nth-child(odd) {
        background: var(--colors-gray-light-95);
    }

    &.single {
        div:nth-child(even) {
            background: var(--colors-gray-light-95);
        }

        div:nth-child(odd) {
            background: transparent;
        }
    }
}

.features-wrapper {
    @include flex-row;
    gap: 16px;
    padding: 24px 20px;
    background-color: none;

    &.spread {
        justify-content: space-between;
    }
}

.single-check {
    flex-shrink: 0;
}

@media screen and (max-width: 1280px) {
    .pricing-table-wrapper,
    .single-package-wrapper {
        padding: 0 60px;
    }

    .pricing-table {
        th {
            padding: var(--sp12) var(--sp12) var(--sp16);
            text-align: center;
        }

        thead tr,
        tbody td {
            @include with-count(3) {
                width: 280px;
                max-width: 280px;
            }

            @include with-count(4) {
                width: 210px;
                max-width: 210px;
            }

            @include with-count(5) {
                width: 145px;
                max-width: 145px;
            }

            @include with-count(6) {
                width: 132px;
                max-width: 132px;
            }
        }
    }

    .compare-text-cell {
        text-align: left !important;
    }

    .package-header-content {
        gap: 12px;
    }

    .package-title-wrap {
        text-align: center;
    }

    .package-subtitle {
        @include caption-M;
        text-align: center !important;

        margin-bottom: 6px;
    }

    .package-text-wrapper {
        justify-content: center;
    }

    .package-primary-text {
        text-align: center;
    }

    .package-secondary-text {
        @include subtitle-L;
        text-align: center;
    }

    .small {
        .package-primary-text {
            display: block;
        }
        .package-secondary-text {
            display: block;

            span {
                display: none;
            }
        }
    }

    .package-text {
        @include flex-column;
        align-items: center;
    }

    .package-header-wrap {
        padding: 12px 0;
    }

    .button {
        @include caption-M;
        padding: 8px 0 !important;
        height: 32px;
    }

    .package-header-content.single {
        text-align: left;

        .package-subtitle,
        .package-title-wrap {
            text-align: left !important;
        }
    }

    .single-package-wrapper {
        gap: 16px;
    }

    .list-wrapper {
        padding: 0 12px;
    }
}

@media screen and (max-width: 960px) {
    .pricing-table-wrapper,
    .single-package-wrapper {
        padding: 0 40px;
    }

    .pricing-table {
        th {
            padding: var(--sp12) var(--sp8) var(--sp16);
        }

        thead tr,
        tbody td {
            @include with-count(3) {
                width: 228px;
                max-width: 228px;
            }

            @include with-count(4) {
                width: 168px;
                max-width: 168px;
            }

            @include with-count(5) {
                width: 126px;
                max-width: 126px;
            }

            @include with-count(6) {
                width: 100px;
                max-width: 100px;
            }
        }
    }

    .single-package-wrapper {
        gap: 8px;
    }

    .graphic {
        background-image: url('/images/plan-benefits-2.png');
        width: 400px;
    }
}

.bulk-message {
    @include text-M;
    margin-top: 20px;
    padding: 12px;
    text-align: center;

    color: var(--colors-primary);
    cursor: pointer;
}
