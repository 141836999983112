@import '@/styles/mixins.scss';

.content-wrap {
    padding: 70px 120px;

    &.wide {
        padding: 70px 70px;
    }
}

.posts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(383px, 1fr));
    margin: 52px 0;
    gap: 25px;
}

/* starting ENTER animation */
.menu-icon.menu-button-enter {
    opacity: 0.01;
    transform: translateY(-10px);
}

/* ending ENTER animation */
.menu-icon.menu-button-enter-active {
    transition: 150ms ease-out;
    transition-delay: 100ms;
    transform: translateY(0px);
    opacity: 1;
}

/* starting EXIT animation */
.menu-icon.menu-button-exit {
    opacity: 1;
    transform: translateY(0px);
}

/* ending EXIT animation */
.menu-icon.menu-button-exit-active {
    transition: 150ms ease-out;
    transition-delay: 100ms;
    transform: translateY(0);
    opacity: 0.01;
}

.button-wrap {
    @include flex-center;
}

@media screen and (max-width: 834px) {
    .content-wrap {
        padding: 70px 20px;

        &.wide {
            padding: 70px 20px;
        }
    }

    .posts {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 600px) {
    .posts {
        grid-template-columns: 1fr;
    }
}
