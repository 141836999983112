@import '@/styles/mixins.scss';

.section {
    overflow: initial;
}

.section-wrap {
    @include flex-column;
    padding: 100px 0 !important;
    gap: 32px;

    &.mobile {
        padding: 100px 40px 80px;
    }
}

.title-wrap {
    @include flex-column;
    gap: 24px;
    justify-content: center;
    align-items: center;
}

.tab-nav {
    @include flex-row;
    justify-content: center;
    padding: 6px;
    border-radius: 24px;
    border: 1px solid var(--colors-mono-black-opacity-5, rgba(0, 0, 0, 0.05));
    background: var(--colors-mono-white-opacity-100, #fff);
    width: fit-content;
    margin: 0 auto;
}

.tab {
    @include caption-M;
    @include flex-center;
    color: var(--colors-gray-dark-20, #7a828f);
    padding: 6px 12px;
    border-radius: 50px;
    transition: all 0.2s ease;

    &:hover {
        cursor: pointer;
    }

    &.active {
        color: var(--colors-primary-light-90, #f0f6ff);
        background: var(--colors-primary, #1570ef);
    }
}

@media screen and (max-width: 1280px) {
    .section-wrap {
        padding: 100px 0 !important;
    }
}

@media screen and (max-width: 960px) {
    .section-wrap {
        padding: 100px 0 !important;
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 80px 0 !important;

        &.mobile {
            padding: 80px 0;
        }
    }
}

@media screen and (max-width: 524px) {
    .section-wrap {
        padding: 80px 0 !important;

        &.mobile {
            padding: 80px 0;
        }
    }
}
