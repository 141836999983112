@import '@/styles/mixins.scss';

.section-wrapper {
    background: var(--colors-gray-light-95);
    padding: 40px 0;
    margin: 40px 0;
}

.content-wrapper {
    @include flex-center;
    flex-direction: column;
    gap: 16px;
}

.title {
    @include headline-2;
    color: var(--colors-gray-dark-90);
    margin: 0;
    text-align: center;
}

.subtitle {
    @include caption-M;
    color: #175cd3;
    margin: 0;
}

.reviews-wrapper {
    position: relative;
    // padding: 0 16px;

    margin-top: 20px;

    opacity: 1;
    transition: 150ms ease-in-out;

    &.hidden {
        opacity: 0;
    }
}

.arrow-wrapper {
    @include flex-center;
    position: absolute;
    top: 0;

    height: 100%;
    padding: 4px;

    cursor: pointer;
    user-select: none;

    opacity: 1;
    transition: 200ms ease-in-out;

    &.left {
        right: calc(100% + 12px);
    }
    &.right {
        right: -44px;
    }

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    .arrow {
        width: 24px;
        stroke: var(--colors-gray-light-50);

        transition: 200ms ease-in-out;
    }

    &:hover .arrow {
        stroke: var(--colors-gray);
    }
}

.reviews-carousel-wrapper {
    overflow: hidden;
}

.reviews-carousel {
    display: grid;
    grid-template-columns: repeat(12, var(--review-width));
    grid-template-rows: 1fr;

    gap: 24px;
}

// Review Box
.review-box {
    @include flex-column;
    width: 100%;
    min-height: 244px;
    padding: 32px;
    border-radius: 16px;
    gap: 16px;
    border: 1px solid var(--colors-gray-light-80);
    flex-shrink: 0;
    transition: 200ms ease-out;
    background: white;

    cursor: pointer;
}

.reviewer {
    @include flex-row;
    align-items: center;
    position: relative;
    gap: 16px;
}

.reviewer-details {
    @include flex-column;
    flex-grow: 1;
}

.name {
    @include caption-L;
    color: var(--colors-gray-dark-80);
}

.date {
    @include text-S;
    color: var(--colors-gray-dark-40);
}

.stars {
    height: 18px;
}

.comment {
    @include text-S;
    color: var(--colors-gray-dark-50);
    display: -webkit-box;

    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    &.full {
        display: block;
        height: fit-content;
    }
}

.google {
    flex-shrink: 0;
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 0 60px;
    }

    .wrapper {
        height: fit-content;
    }

    .box {
        min-height: auto;
        padding: 24px;
    }
}

@media screen and (max-width: 524px) {
    .section-wrap {
        padding: 0 40px;
    }

    .box {
        padding: 24px;
    }

    .arrow-wrapper {
        &.left {
            right: calc(100% + 6px);
        }
        &.right {
            right: -38px;
        }
    }
}
