@import '@/styles/mixins.scss';

.wrapper {
    width: 100%;
}

.content-wrapper {
    @include flex-column;
    gap: 16px;
}

.title {
    @include headline-3;
    color: var(--colors-primary-dark-80);
}

.description {
    @include text-M;
    color: var(--colors-gray-dark-30);

    * {
        margin: 0;
    }
}
