@import '@/styles/mixins.scss';

.wrapper {
    @include flex-center;
    width: 100%;
    height: 202px;
    transition: 150ms ease-out;
    cursor: pointer;
    text-decoration: none;
    gap: 24px;

    &:hover {
        transform: scale(1.01);
    }

    transform: translateX(-50px);
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transform: translateX(0);
    }
}

.image {
    width: 256px;
    height: 202px;
    position: relative;
    flex-shrink: 0;
    border-radius: 16px;
    overflow: hidden;

    img {
        object-fit: cover;
        object-position: center;
    }
}

.content {
    @include flex-column;
    gap: 8px;
    padding: 16px;
    flex-grow: 1;
}

.title {
    @include headline-3;
    display: -webkit-box;
    margin: 0;
    height: 38px;
    color: var(--colors-primary-dark-90);

    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.excerpt {
    @include text-L;
    display: block;
    width: 80%;
    height: 87px;
    margin: 0;
    color: var(--colors-gray-dark-10);

    overflow: hidden;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
}

.author {
    @include flex-center;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px 0;

    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 100px;
        overflow: hidden;
    }

    .author-details {
        @include flex-row;
        gap: 4px;
    }

    .name {
        @include subtitle-S;
        color: var(--colors-primary-dark-90);
    }

    .author-details {
        @include flex-center;
        gap: 8px;
        @include text-S;
        color: var(--colors-gray-dark-10);
    }

    .text-separator {
        width: 6px;
        height: 6px;
        border-radius: 100px;
        background-color: var(--colors-gray-light-30);
    }
}
