@import '/styles/mixins';

.verify-form {
    @include flex-column;
    flex-grow: 1;
}

.content-wrapper {
    @include flex-column;
    gap: 16px;

    margin-bottom: 20px;
}

.title {
    @include headline-3;
    color: var(--colors-primary-dark-80);
}

.description {
    @include text-M;
    color: var(--colors-gray-dark-30);

    * {
        margin: 0;
    }
}
.form {
    @include flex-column;
    width: 100%;
}

.verification-input-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    gap: 12px;

    width: fit-content;
    margin: auto;

    flex-grow: 1;
}

.verify-input {
    @include headline-1;
    color: var(--colors-primary);
    text-align: center;
    width: 60px;
    padding: 18px 0;
    outline: none;
    border: none;
    border: 2px solid var(--colors-gray-light-80);
    border-radius: 8px;
    margin: auto;
}

.verify-button {
    @include flex-center;
    @include subtitle-S;

    width: 100% !important;
    height: 44px !important;
    gap: 4px;
    border-radius: 8px;

    height: 38px;
    cursor: pointer;
    outline: none;

    transition: 150ms ease-in-out;

    color: var(--white);
    background: var(--colors-primary);
    border: none;

    height: 38px;
    padding: 10px 20px;
    border-radius: 8px;

    &:hover {
        background: var(--colors-primary-dark-10);
    }

    &:active {
        background: var(--colors-primary-dark-20);
    }

    &:disabled {
        pointer-events: none;
        background: var(--colors-primary-light-50);
        color: var(--colors-mono-white-opacity-80);
    }
}

.clear-button {
    @include subtitle-S;
    @include flex-center;
    text-align: center;
    margin: 16px 0;
    color: var(--colors-primary);

    span {
        cursor: pointer;
    }
}

.login-label {
    @include subtitle-S;
    display: block;
    color: var(--colors-gray-dark-60);
    margin-bottom: 8px;
}

.resend-code-wrapper {
    @include subtitle-S;
    @include flex-center;
    color: var(--colors-gray-dark-40);
    margin: 16px 0;
}

.resend-code {
    cursor: pointer;
    color: var(--colors-primary);
}

@media screen and (max-width: 524px) {
    .verification-input-container {
        gap: 4px;
    }
    .verify-input {
        height: auto;
        width: 45px;
        padding: 6px 0;
    }
}
