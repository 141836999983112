@import '@/styles/mixins';

.wrapper {
    position: relative;
    width: 100%;

    &.disabled::before {
        opacity: 0;
    }

    svg {
        stroke: var(--colors-gray-dark-30);
        width: 20px;
        position: absolute;
        right: 10px;
        top: 13px;
        pointer-events: none;
    }
}

.search {
    padding-right: 36px !important;
}

.search:focus + .choices {
    display: block;
}

.choices {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    background-color: var(--white);
    display: none;
    list-style: none;
    padding: 0;
    margin: 4px 0 0;
    max-height: 250px;
    overflow: auto;
    position: absolute;
    z-index: 2;
    border-radius: 8px;
    width: 100%;
}

.choices li {
    padding: 12px 8px;

    &:hover {
        background: #f5f5f5;
        cursor: pointer;
    }

    &.hidden {
        display: none;
    }
}
