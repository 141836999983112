@import '@/styles/mixins.scss';

.multiple-choice-wrapper {
    display: grid;
    gap: 12px;

    &.two-column {
        grid-template-columns: repeat(2, 1fr);
    }
}

.choice {
    input[type='radio'],
    input[type='checkbox'] {
        display: none;
    }

    label {
        @include flex-row;
        @include subtitle-S;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        height: 100%;

        color: var(--colors-gray-dark-30);
        background: var(--white);
        border: 1px solid var(--colors-mono-black-opacity-5);

        padding: 12px;
        border-radius: 8px;
        transition: 100ms ease-in-out border-color, 100ms ease-in-out color;

        cursor: pointer;
        user-select: none;

        &:hover {
            // Adjust padding for extra border width.
            padding: 11px;

            border-color: var(--colors-primary-light-50);
            border-width: 2px;

            color: var(--colors-primary-light-20);

            .icon-box svg {
                stroke: var(--colors-primary-light-20);
            }
        }
    }

    input:checked + label {
        color: var(--colors-primary);

        border: 2px solid var(--colors-primary-light-20);

        // Adjust padding for extra border width.
        padding: 11px;

        .icon-box svg {
            stroke: var(--colors-primary);
        }

        .checkbox {
            border: 1px solid var(--colors-primary);

            svg {
                opacity: 1;
                stroke: var(--colors-primary);
            }

            &.radio {
                background-color: var(--colors-primary);
                box-shadow: inset 0 0 0 4px var(--white);
            }
        }
    }
}

.label-wrap {
    @include flex-row;
    align-items: center;
    gap: 8px;
}

.icon-box {
    @include flex-center;
    width: 16px;
    height: 16px;

    svg {
        stroke: var(--colors-gray-dark-20);
    }
}

.checkbox {
    @include flex-center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--colors-gray-light-50);
    transition: 0.15s ease-in-out;
    flex-shrink: 0;

    cursor: pointer;
    user-select: none;

    svg {
        stroke: var(--colors-primary);
        opacity: 0;
        transition: 0.15s ease-in-out;
        width: 14px;
    }

    &.radio {
        border-radius: 100px;
    }
}

@media screen and (max-width: 1280px) {
    .multiple-choice-wrapper.two-column {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 960px) {
    .multiple-choice-wrapper.two-column {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 764px) {
    .multiple-choice-wrapper.two-column {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 524px) {
}
