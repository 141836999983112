@import '/styles/mixins';

.form-wrapper {
    position: relative;

    flex-shrink: 0;
    width: 100%;
    flex-grow: 1;
}

.container {
    @include flex-column;
    position: relative;
    transition: 0.3s;

    flex-grow: 1;

    gap: 24px;

    a {
        @include subtitle-S;
        cursor: pointer;
        color: var(--colors-primary-dark-20);
        text-decoration: none;
    }
}

.error-container p {
    @include text-S;
    display: block;
    color: var(--colors-error);
    text-align: left;
    margin-top: 6px;
}

.content-wrapper {
    @include flex-column;
    gap: 16px;

    margin-bottom: 20px;
}

.title {
    @include headline-3;
    color: var(--colors-primary-dark-80);
}

.description {
    @include text-M;
    color: var(--colors-gray-dark-30);

    * {
        margin: 0;
    }
}

.form {
    @include flex-column;
    width: 100%;
}

// Form specific

.input-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    gap: 12px;
}

.input {
    @include text-M;
    width: 100%;

    line-height: 39px;

    padding: 0 13px;
    border: 1px solid var(--colors-mono-black-opacity-10);
    border-radius: 8px;

    color: var(--colors-gray-dark-40);

    outline: none;

    &:focus {
        border: 1px solid var(--colors-mono-black-opacity-30);
    }

    &:invalid:not(:placeholder-shown) {
        border: 1px solid var(--colors-error-light-40);
    }
}

.icon-input {
    padding-right: 36px;

    &:not(:placeholder-shown):invalid + .error-icon {
        display: block;
    }

    &:focus + .password-reveal {
        display: block;
    }
}

.error-icon,
.password-reveal {
    display: none;
    position: absolute;
    top: 10px;
    right: 16px;
    width: 16px;
    height: 16px;
}

.error-icon {
    svg {
        stroke: var(--colors-error);
    }
}

.password-reveal {
    cursor: pointer;

    svg {
        stroke: var(--colors-gray-light-40);
    }

    &:hover {
        svg {
            stroke: var(--colors-gray);
        }
    }

    &.password-reveal--active {
        display: block;

        svg {
            stroke: var(--colors-gray-dark-70);
        }
    }
}

// Register extras

.or-separator {
    @include flex-center;
    color: var(--colors-gray-dark-0, #98a2b3);
    gap: 8px;

    .line {
        height: 1px;

        flex-grow: 1;

        background: var(--colors-gray-light-80, #eaecf0);
    }

    span {
        @include subtitle-S;
        text-transform: uppercase;
        flex-shrink: 0;
    }
}

.buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 12px;
}

.button {
    @include subtitle-S;

    height: 38px;
    cursor: pointer;
    outline: none;

    transition: 150ms ease-in-out;

    &.login-button {
        @include flex-center;

        color: var(--white);
        background: var(--colors-primary);
        border: none;

        height: 38px;
        padding: 0 20px;
        line-height: 36px;
        border-radius: 8px;

        &:hover {
            background: var(--colors-primary-dark-10);
        }

        &:active {
            background: var(--colors-primary-dark-20);
        }

        &:disabled {
            pointer-events: none;
            background: var(--colors-primary-light-50);
            color: var(--colors-mono-white-opacity-80);
        }
    }

    &.auth-button {
        @include flex-center;

        gap: 12px;

        color: var(--colors-gray);
        background: var(--white);
        border: 1px solid var(--colors-gray-light-70);

        height: 38px;
        padding: 10px 20px;
        border-radius: 8px;

        &:hover {
            border: 1px solid var(--colors-gray-light-50);
        }
    }
}

.login-link {
    @include text-S;
    display: block;
    text-align: center;
    color: var(--colors-gray-dark-0, #98a2b3);
}

.enter {
    opacity: 0;
}

/* ending ENTER animation */
.enter-active {
    transition: 150ms ease-in-out 150ms;
    opacity: 1;
}

.exit {
    opacity: 1;
}

.exit-active {
    transition: 150ms ease-in-out;
    opacity: 0;
}

.password-banner {
    @include caption-M;

    color: var(--colors-success-dark-30);
    background: var(--colors-success-light-95);
    border: 1px solid var(--colors-success-light-70);
    border-radius: 8px;

    padding: 12px;
    margin-top: 12px;

    span {
        display: block;
        padding-top: 4px;
    }
}

.back-button {
    @include flex-center;

    height: auto;

    color: var(--colors-gray-dark-20, #7a828f);
    background: white;
    border: none;

    padding: 0;
    margin-bottom: 24px;

    gap: 4px;

    &:hover {
        color: var(--colors-gray-dark-50);
    }

    &:active {
        color: var(--colors-gray-dark-70);
    }

    &:disabled {
        color: var(--colors-gray-light-50);
        cursor: default;
    }
}

@media screen and (max-width: 1280px) {
    .buttons {
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media screen and (max-width: 960px) {
    .buttons {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 764px) {
    .buttons {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 524px) {
}
