@import '@/styles/mixins.scss';

.content-wrap {
    @include flex-center;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    max-width: 1300px !important;
    width: calc(100vw - 140px);
    margin: 100px auto !important;
    opacity: 0.01;
    transform: translateY(60px);

    padding: 0 !important;

    &.stretch {
        max-width: unset;
        margin: 70px 60px;
    }

    &.enter {
        opacity: 1;
        transform: translateY(0px);
        // transition: 150ms ease-out;
    }

    &.blog-cta {
        max-width: 1140px !important;
    }
}

.content {
    @include flex-center;
    flex-direction: column;
    gap: 24px;
    color: white;

    opacity: 0.01;
    transform: translateY(40px);

    max-width: 800px;

    padding: 120px 100px;

    margin: auto;

    &.enter {
        opacity: 1;
        transform: translateY(0px);
        transition: 150ms ease-out;
        transition-delay: 200ms;
    }
}

.button {
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transition: 150ms ease-out;
        transition-delay: 300ms;
    }
}

.title {
    @include headline-1;
    text-align: center;
    color: white;

    margin: 0;

    p {
        margin: 0;
    }
}

.description {
    @include text-L;
    text-align: center;
    color: var(--colors-primary-light-90);

    margin: 0;
}

.buttons {
    @include flex-row;
    gap: 10px;
}

.blue-text {
    color: var(--colors-primary) !important;
}

@media screen and (max-width: 1280px) {
    .content-wrap {
        margin: 100px 60px !important;
        width: unset;
    }
}

@media screen and (max-width: 960px) {
    .content-wrap {
        margin: 100px 70px !important;
    }
}

@media screen and (max-width: 764px) {
    .content-wrap {
        margin: 80px 0 !important;
        border-radius: 0;
    }

    .content {
        padding: 100px 40px;
    }
}

@media screen and (max-width: 524px) {
    .content {
        padding: 100px 24px;
    }
}
