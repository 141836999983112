@import '@/styles/mixins.scss';

.wrapper {
    @include flex-center;
    width: 100%;
    min-height: 44px;
    padding: 12px 22px;
    gap: 24px;

    background: var(--colors-mono-white-opacity-20, rgba(255, 255, 255, 0.2));

    opacity: 0.01;

    &.enter {
        opacity: 1;

        transition: 150ms ease-out;
        transition-delay: 100ms;
    }

    .message {
        @include caption-M-upper;
        text-align: center;
        color: var(--colors-mono-white-opacity-100, #fff);
        opacity: 0;
    }

    .countdown {
        @include caption-M-upper;
        text-align: center;
        color: var(--colors-mono-white-opacity-100, #fff);
        min-width: 120px;
        opacity: 0;
    }

    &.dark {
        background: var(--colors-primary-light-95);

        .message {
            color: var(--colors-primary);
        }

        .countdown {
            color: var(--colors-primary);
        }
    }

    &.hidden {
        display: none !important;
    }
}

.visible {
    opacity: 1 !important;
    transition: 150ms ease-out;
}

@media screen and (max-width: 500px) {
    .wrapper {
        flex-direction: column;
        gap: 12px;
    }
}
