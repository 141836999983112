@import '@/styles/mixins.scss';

.wrapper {
    background: white;
    border-radius: 16px;

    box-shadow: var(--shadow-card-dark);
    border: 1px solid var(--colors-mono-black-opacity-5);

    padding: 40px;
    width: 100%;

    position: relative;

    overflow: hidden;
}

.question-flow {
    @include flex-column;

    gap: 20px;

    width: 100%;
    min-height: 505px;
    margin: auto;
}

.question-tag {
    @include caption-M;
    color: var(--colors-primary-dark-20);
    background: var(--colors-primary-light-90);

    padding: 2px 6px;
    border-radius: 4px;

    width: fit-content;
}

.question-wrapper {
    flex-grow: 1;
}

.question-buttons {
    @include flex-row;
    justify-content: space-between;

    margin-top: 4px;
}

.button {
    @include subtitle-S;

    cursor: pointer;
    outline: none;

    transition: 150ms ease-in-out;
}

.back-button {
    color: var(--colors-gray-dark-20, #7a828f);
    background: white;
    border: none;

    padding: 0;

    &:hover {
        color: var(--colors-gray-dark-50);
    }

    &:active {
        color: var(--colors-gray-dark-70);
    }

    &:disabled {
        color: var(--colors-gray-light-50);
        cursor: default;
    }
}

.continue-button {
    @include flex-center;

    color: var(--white);
    background: var(--colors-primary);
    border: none;

    height: 38px;
    padding: 0 20px;
    line-height: 36px;
    border-radius: 8px;

    &:hover {
        background: var(--colors-primary-dark-10);
    }

    &:active {
        background: var(--colors-primary-dark-20);
    }

    &:disabled {
        pointer-events: none;
        background: var(--colors-primary-light-50);
        color: var(--colors-mono-white-opacity-80);
    }
}

.right {
    .question-animation-enter {
        transform: translateX(50px);
        opacity: 0;
    }

    /* ending ENTER animation */
    .question-animation-enter-active {
        transition: 150ms ease-in-out;
        transform: translateX(0);
        opacity: 1;
    }

    .question-animation-exit {
        opacity: 1;
        transform: translateX(0px);
    }

    .question-animation-exit-active {
        transition: 150ms ease-in-out;
        transform: translateX(-50px);
        opacity: 0;
    }
}

.left {
    .question-animation-enter {
        transform: translateX(-50px);
        opacity: 0;
    }

    /* ending ENTER animation */
    .question-animation-enter-active {
        transition: 150ms ease-in-out;
        transform: translateX(0);
        opacity: 1;
    }

    .question-animation-exit {
        opacity: 1;
        transform: translateX(0px);
    }

    .question-animation-exit-active {
        transition: 150ms ease-in-out;
        transform: translateX(50px);
        opacity: 0;
    }
}

.progress-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.progress-bar {
    height: 5px;
    background: var(--colors-primary-light-30);

    transition: 300ms ease-out;
}

// PROMPT OVERLAY

.prompt-overlay {
    @include flex-center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: var(--colors-mono-black-opacity-20);

    backdrop-filter: blur(1px);
}

.prompt {
    @include flex-center;
    @include caption-L;
    color: var(--colors-gray-dark-50);
    margin: 0;

    padding: 20px 32px;
    border-radius: 8px;

    background: white;
    box-shadow: var(--shadow-popover);

    animation: wobble 4s ease-in-out 0s infinite;
}

@keyframes wobble {
    0%,
    45%,
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    7.5% {
        -webkit-transform: translateX(-32px) rotate(-10deg);
        transform: translateX(-32px) rotate(-10deg);
    }

    15% {
        -webkit-transform: translateX(calc(32px / 2)) rotate(10deg);
        transform: translateX(calc(32px / 2)) rotate(10deg);
    }

    22.5% {
        -webkit-transform: translateX(calc(-32px / 2)) rotate(calc(-10deg / 1.8));
        transform: translateX(calc(-32px / 2)) rotate(calc(-10deg / 1.8));
    }

    30% {
        -webkit-transform: translateX(calc(32px / 3.3)) rotate(calc(10deg / 3));
        transform: translateX(calc(32px / 3.3)) rotate(calc(10deg / 3));
    }

    37.5% {
        -webkit-transform: translateX(calc(-32px / 5.5)) rotate(calc(-10deg / 5));
        transform: translateX(calc(-32px / 5.5)) rotate(calc(-10deg / 5));
    }
}

.enter {
    opacity: 0;
}

/* ending ENTER animation */
.enter-active {
    transition: 150ms ease-in-out;
    opacity: 1;
}

.exit {
    opacity: 1;
}

.exit-active {
    transition: 150ms ease-in-out;
    opacity: 0;
}

// NOTIFICATIONS

.toasts-wrap {
    position: absolute;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    background: var(--colors-mono-black-opacity-20);
}

.toast {
    @include flex-center;
    @include caption-L;

    position: absolute;
    right: 20px;

    padding: 12px 20px;
    border-radius: 8px;
    box-shadow: var(--shadow-popover);
    background: white;
    border: 1px solid var(--colors-mono-black-opacity-5);

    margin-top: 20px;

    color: var(--colors-gray-dark-70);

    transition: 300ms ease-out;

    &.hidden {
        opacity: 0;
    }
}

.toasts-exit {
    opacity: 1;
    right: 20px;
}

.toasts-exit-active {
    transition: 150ms ease-in-out;
    opacity: 0;
    right: 0px;
}

.emoji {
    width: 16px;
    margin-left: 8px;
}

// Media queries

@media screen and (max-width: 1280px) {
    .wrapper {
        padding: 32px;
    }
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 764px) {
    .wrapper {
        border-radius: 0;
        box-shadow: none;
        border: none;
        padding: 0;

        overflow: visible;
    }

    .progress-wrapper {
        display: none;
    }

    .toasts-wrap,
    .prompt-overlay {
        background: var(--colors-mono-white-opacity-40);
        backdrop-filter: blur(1px);
    }

    .toast {
        margin-top: 0;
    }
}

@media screen and (max-width: 524px) {
}
