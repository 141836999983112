@import '@/styles/mixins.scss';

.container {
    @include flex-column;
    gap: 52px;
    padding: 80px 70px;
    align-items: center;
}

.header {
    @include flex-column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.subtitle {
    @include headline-6;
    color: var(--colors-primary-dark-0, #1570ef);
    text-align: center;
}

.title {
    @include headline-1;
    color: var(--colors-primary-dark-90, #020b18);
    text-align: center;
}

.box {
    @include flex-row;
    gap: 52px;
    width: 1330px;
    padding: 52px;
    border-radius: 16px;
    background: var(--white);
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
}

.intro {
    @include headline-5;
    color: var(--colors-gray-dark-40, #5b616b);
}

.questions-wrap {
    @include flex-column;
    gap: 40px;
}

.question-container {
    @include flex-column;
    gap: 12px;

    > span {
        @include subtitle-M;
        color: var(--colors-gray-dark-50, #4c515a);
    }
}

.quotation-wrap {
    @include flex-column;
    padding: 32px;
    width: 440px;
    flex-shrink: 0;
    gap: 32px;
    border-radius: 16px;
    border: 1px solid var(--colors-gray-light-80, #eaecf0);
    background: var(--white);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.05), 0px 12px 16px -4px rgba(16, 24, 40, 0.1);
}

.pricing-wrapper {
    @include flex-column;
    gap: 10px;

    .summary-text {
        @include text-S;
        color: var(--colors-gray-dark-20, #7a828f);
        margin: 0;
    }
}

.tag {
    @include flex-center;
    padding: 4px 12px;
    gap: 12px;
    border-radius: 16px;
    background: var(--colors-gray-light-95, #fafafb);
    width: fit-content;
    transition: 150ms ease-out;

    &.red {
        background: var(--colors-error-light-95, #fef6f5);
        span {
            color: var(--colors-error-dark-10, #c03625);
        }
    }

    span {
        @include subtitle-S;
        color: var(--colors-gray-dark-50, #4c515a);
        transition: 150ms ease-out;
    }
}

.price-wrap {
    @include flex-row;
    align-items: flex-end;
}

.price {
    @include hero-headline;
    color: var(--colors-primary-dark-70, #062248);
    transition: 150ms ease-out;

    &.red {
        color: var(--colors-error, #ff4d4f);
    }
}

.savings-wrap {
    display: flex;
    padding: 8px 0px;
    align-items: flex-end;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
}

.savings {
    @include headline-2;
    color: var(--colors-gray-dark-0, #98a2b3);
    transition: 150ms ease-out;

    &.red {
        color: var(--colors-error-light-40, #f68e82);
    }
}

.separator {
    height: 1px;
    background: var(--colors-gray-light-70, #e0e3e8);
}

.perks-title {
    @include caption-L;
    font-weight: 500;
    color: var(--colors-gray-dark-20, #7a828f);
}

.perks {
    @include flex-column;
    gap: 16px;
}

.perk {
    @include flex-center;
    gap: 12px;

    span {
        @include text-M;
        color: var(--colors-gray-dark-20, #7a828f);
        flex-grow: 1;
    }
}

.get-started {
    @include flex-center;
    width: 100%;
    border-radius: 8px;

    a {
        @include subtitle-M;
        color: var(--white);
    }
}

.radio-group {
    @include flex-column;
    gap: 12px;
}

.svg-container {
    @include flex-center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid var(--colors-gray-light-50, #cbd1d9);
    background: var(--white, #fff);
}

/* Hide the browser's default checkbox */
.radio {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;

    &:checked + .radio-container .svg-container {
        border: 1px solid var(--colors-primary, #1570ef);
        background: var(--colors-primary, #1570ef);
    }
}

.radio-container {
    @include text-M;

    display: inline-flex;

    gap: 8px;
    color: var(--colors-gray-dark-30, #6a717d);
    align-items: center;
    position: relative;
    cursor: pointer;

    svg {
        fill: var(--white);
    }
}

.slider {
    height: 52px;
}

.track {
    position: relative;
    top: 6px;
    height: 6px;
    border-radius: 4px;
}

.track-0 {
    background: var(--colors-primary, #1570ef);
}

.track-1 {
    background: var(--colors-gray-light-70, #e0e3e8);
}

.mark {
    @include flex-center;
    top: 38px;
    width: 20px;
}
.mark-line {
    width: 2px;
    height: 10px;
    background: var(--colors-mono-black-opacity-20, rgba(0, 0, 0, 0.2));
}

.thumb {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: var(--white, #fff);
    filter: drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.1));
    outline: none;
}

.thumb-active {
    &:hover {
        cursor: grab;
    }

    &:active {
        cursor: grabbing;
    }
}

.value-container {
    @include flex-center;
    @include subtitle-XS;
    width: max-content;
    position: absolute;
    top: 32px;
    left: -12px;
    padding: 8px 12px;
    border-radius: 8px;
    background: var(--white, #fff);
}

@media (max-width: 1440px) {
    .box {
        width: 1000px;
    }
}

@media (max-width: 1170px) {
    .box {
        width: 800px;
        flex-direction: column;
        padding: 32px;
    }

    .quotation-wrap {
        width: 100%;
    }
}

@media (max-width: 960px) {
    .box {
        width: unset;
    }
}

@media (max-width: 764px) {
    .container {
        padding: 80px 40px;
    }
}

@media (max-width: 524px) {
    .container {
        padding: 80px 20px;
    }
}
